// import logo from './assets/logo192.png'
import './App.css'
import {  useRef } from 'react' //useState, useEffect,
import { Button } from '@mui/material'
import { Download, ArrowDropDownTwoTone } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
// import { Particles } from 'react-particles-js';

const DownloadButton = styled(Button)({
  fontWeight: "bold",
  fontSize: "16px",
  color: "#4696e5",
  backgroundColor: '#FFFFFF',
  borderColor: '#FFFFFF',
  fontFamily: "Arial, Helvetica, sans-serif",
  width: "160px",
  height: "50px",
  '&:hover': {
    color: "#0062cc",
    backgroundColor: '#FFFFFFCC',
    borderColor: '#FFFFFF',
  },
  '&:active': {
    backgroundColor: '#FFFFFFCC',
    borderColor: '#FFFFFF',
  },
});

const DownloadButton2 = styled(Button)({
  fontWeight: "bold",
  fontSize: "16px",
  color: "#FFFFFF",
  backgroundColor: '#4696e5',
  borderColor: '#4696e5',
  fontFamily: "Arial, Helvetica, sans-serif",
  width: "160px",
  height: "50px",
  '&:hover': {
    color: "#FFFFFFCC",
    backgroundColor: '#4696e5',
    borderColor: '#FFFFFF',
  },
  '&:active': {
    backgroundColor: '#4696e5',
    borderColor: '#FFFFFF',
  },
});

const MoreInfoButton = styled(Button)({
  fontWeight: "bold",
  fontSize: "16px",
  color: "#FFFFFF",
  background: '#FFFFFF22',
  border: "2px solid #FFFFFF55",
  // borderColor: '#FFFFFF',
  fontFamily: "Arial, Helvetica, sans-serif",
  width: "160px",
  height: "50px",
  '&:hover': {
    color: "#FFFFFFCC",
    backgroundColor: '#FFFFFF33',
    border: "2px solid #FFFFFF55",
  },
  '&:active': {
    backgroundColor: '#FFFFFF33',
    border: "2px solid #FFFFFF55",
  },
});

const HomePage = () => {

  const detailsRef = useRef(null)
  // const fileUrl = apkPath
  const apkName = "cardrecorder1142.apk"
  const fileUrl = process.env.PUBLIC_URL + "/files/" + apkName
  const beianUrl = "https://beian.miit.gov.cn"
  // const publicDir = process.env.PUBLIC_URL
  console.info("public: " + fileUrl)

  return (
    
    <div className="HomePage" style={{
      width: "100%",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      lineHeight: 2,
      borderLeft: "1px solid lightgray",
      borderRight: "1px solid lightgray",
      padding: "0px",
      borderRadius: "4px",
      color: '#FFFFFF',
      fontSize: "16px",
      letterSpacing: "1px",
      fontFamily: "Arial, Helvetica, sans-serif",
    }}>

      {/* <Particles
        params={{
          "particles": {
            "number": {
              "value": 60,
              "density": {
                "enable": true,
                "value_area": 1500
              }
            },
            "line_linked": {
              "enable": true,
              "opacity": 0.02
            },
            "move": {
              "direction": "right",
              "speed": 0.05
            },
            "size": {
              "value": 1
            },
            "opacity": {
              "anim": {
                "enable": true,
                "speed": 1,
                "opacity_min": 0.05
              }
            }
          },
          "interactivity": {
            "events": {
              "onclick": {
                "enable": true,
                "mode": "push"
              }
            },
            "modes": {
              "push": {
                "particles_nb": 1
              }
            }
          },
          "retina_detect": true
        }} /> */}

      <div className='section1' style={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#4492E0',
        marginLeft: "0px",
      }}>
        <div className='titleContainer' style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0px",
        }}>
          <h1 className='title' style={{
            fontWeight: "bold",
            fontSize: "46px",
            marginLeft: "0px",
            margin: "0px",
            padding: "0px",
            lineHeight: "50px",
            userSelect: "none",
          }}>
            免费记牌器
          </h1>
          <h2 style={{
            fontSize: "20px",
            color: "#d1e5f9",
            lineHeight: "80px",
            userSelect: "none",
          }}>
            安卓手机斗地主跑得快都支持
          </h2>



          <DownloadButton
            startIcon={<Download />}
            variant="contained"
            color="success"
            href={fileUrl}
            download={apkName}
          >
            下载
          </DownloadButton>

          <div style={{ width: "10px", height: "30px" }}></div>

          <MoreInfoButton
            startIcon={<ArrowDropDownTwoTone />}
            variant="contained"
            color="success"
            onClick={()=>{detailsRef.current.scrollIntoView({ behavior: 'smooth' })}}>
            了解更多
          </MoreInfoButton>

        </div>
      </div>

      <div ref={detailsRef} className='section2' style={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "22px",
        // fontWeight: "bold",
        color: "#828282",
        backgroundColor: '#FFFFFF',
        marginLeft: "0px",
      }}>

        <div className='content1' style={{
          width: "100vw",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0px",
        }}>
          支持多种游戏玩法
          <div style={{
            fontSize: "16px",
            width: "90vw",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            实时计算对手余牌，帮助您从容判断，专注策略，轻松搞定对手
          </div>
        </div>

        <div className='line' style={{width:"100%", height:"2px", background: "#99999944"}}>
          
        </div>
        <div className='content2' style={{
          width: "100vw",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0px",
        }}>
          计算准确
          <div style={{
            fontSize: "16px",
            width: "90vw",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            基于AI识别技术，精细打磨，超高准确率，全自动让您解放双手
          </div>
        </div>

        <div className='line' style={{width:"100%", height:"2px", background: "#99999944"}}></div>

        <div className='content2' style={{
          width: "100vw",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0px",
        }}>
          软件小巧
          <div style={{
            fontSize: "16px",
            width: "90vw",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            操作简单，使用方便，界面清新，内存占用极小
          </div>
        </div>

        <div className='line' style={{width:"100%", height:"2px", background: "#99999944"}}></div>

        <div className='content2' style={{
          width: "100vw",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0px",
        }}>
          升级维护
          <div style={{
            fontSize: "16px",
            width: "90vw",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            持续优化升级，越用越好用
          </div>
        </div>

        <div className='line' style={{width:"100%", height:"2px", background: "#99999944"}}></div>

        <div className='content2' style={{
          width: "100vw",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0px",
        }}>
          <DownloadButton2
            startIcon={<Download />}
            variant="contained"
            color="success"
            href={fileUrl}
            download={apkName}
          >
            立即下载
          </DownloadButton2>
        </div>

      </div>

      <div style={{
        width: "100%",
        height: "50px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#f7f7f7",
      }}>
        <div style={{ color: "#464646", fontSize: "12px", textDecoration: "none" }}>
          www.yyrec.cn 版权所有. All Rights Reserved. 
        </div>
        <a style={{ color: "#464646", fontSize: "12px", textDecoration: "none" }} href={beianUrl}>
          蜀ICP备2023030177号-3
        </a>
      </div>

    </div>
  );
}

export default HomePage;
